import { IonButton, IonHeader } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CognitoPropertyKeys, getCurrentUserAttribute } from '../../../../utils/auth/AuthUtils';
import useLogout from '../../../login/useLogout';

const UserMenu: React.FC = () => {
  const [fullName, setFullName] = useState<string | undefined>();

  const { t } = useTranslation();
  const { logOut } = useLogout();

  useEffect(() => {
    getCurrentUserAttribute(CognitoPropertyKeys.name).then((value) => setFullName(value));
  }, []);

  return (
    <>
      <IonHeader>{fullName}</IonHeader>
      <IonButton expand="full" fill="clear" onClick={logOut}>
        {t('logout')}
      </IonButton>
      <IonButton expand="full" fill="clear">
        Placeholder
      </IonButton>
    </>
  );
};

export default UserMenu;
