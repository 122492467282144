export enum Status {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum UserRole {
  EMPLOYEE = 1,
  MANAGER = 2,
  ADMIN = 3,
  SUPER_ADMIN = 4,
}

export enum Language {
  UNDEFINED = '',
  ENGLISH = 'en',
  ESPAÑOL = 'es',
}
