import './DeleteConfirmation.css';
import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export interface DeleteConfirmationProps {
  header?: string;
  message?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteMe: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  header,
  message,
  confirmButtonText,
  isOpen,
  setIsOpen,
  deleteMe,
}) => {
  const { t } = useTranslation();

  if (!header) {
    header = 'delete';
  }
  if (!message) {
    message = 'delete';
  }
  if (!confirmButtonText) {
    confirmButtonText = 'delete';
  }

  return (
    <IonAlert
      cssClass="delete-confirmation"
      header={t(header)}
      message={t(message)}
      isOpen={isOpen}
      buttons={[
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t(confirmButtonText),
          role: 'destructive',
          handler: deleteMe,
        },
      ]}
      onWillDismiss={() => setIsOpen(false)}
    />
  );
};

export default DeleteConfirmation;
