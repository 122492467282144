// @ts-ignore Requires --resolveJsonModule flag on tsc to resolve correctly
import outputs from './outputs.json';

//https://youtu.be/pXd9BCwpjhA?t=2623
const amplifyConfig = {
  aws_project_region: outputs['VeriTask'].ProjectRegion,

  aws_appsync_region: outputs['VeriTask'].ProjectRegion,
  aws_appsync_graphqlEndpoint: outputs['VeriTask'].AppSyncUrl,

  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

  cognito: {
    USER_POOL_ID: outputs['VeriTask'].USERPOOLID,
    APP_CLIENT_ID: outputs['VeriTask'].CLIENTAPPID,
    IDENTITY_POOL_ID: outputs['VeriTask'].IDENTITYPOOLID,
  },

  aws_cognito_region: outputs['VeriTask'].ProjectRegion,
  aws_user_pools_id: outputs['VeriTask'].USERPOOLID,
  aws_user_pools_web_client_id: outputs['VeriTask'].CLIENTAPPID,

  Auth: {
    region: outputs['VeriTask'].ProjectRegion,
    userPoolId: outputs['VeriTask'].USERPOOLID,
    userPoolWebClientId: outputs['VeriTask'].CLIENTAPPID,
  },
};

export default amplifyConfig;
