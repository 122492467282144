import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { cardOutline, homeOutline, peopleOutline, settingsOutline, statsChartOutline } from 'ionicons/icons';
import './Menu.css';
import { URL_PATH_PREFIX } from '../utils/Globals';
import { useEffect, useState } from 'react';
import { CognitoPropertyKeys, getCurrentUserAttribute } from '../utils/auth/AuthUtils';

interface MenuProps {
  history?: any;
}

const Menu: React.FC<MenuProps> = (props) => {
  const [fullName, setFullName] = useState<string | undefined>();

  const location = useLocation();

  useEffect(() => {
    getCurrentUserAttribute(CognitoPropertyKeys.name).then((value) => setFullName(value));
  }, []);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar color="primary">
          <IonImg className="logo" src={'./'  + (URL_PATH_PREFIX === '' ? '' : (URL_PATH_PREFIX + "/")) +  'assets/img/logo.png'} />
          <IonTitle className="logo-title">Admin</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem className="profile-card">
          <div className="menu-header-container">
            <IonImg className="avatar" src={'./'  + (URL_PATH_PREFIX === '' ? '' : (URL_PATH_PREFIX + "/")) +  'assets/img/profile.png'} alt="profile avatar" />
            <div className="menu-user-info">
              <IonLabel className="username">{fullName}</IonLabel>
              <IonLabel className="last-login">
                <h4>Last Login: 1/2/2022</h4>
              </IonLabel>
            </div>
          </div>
        </IonItem>

        <IonItem>
          <IonLabel>Current Plan: Pro</IonLabel>
          <IonButton>Upgrade</IonButton>
        </IonItem>

        <IonList>
          <IonMenuToggle>
            <IonItem button routerLink="/home" routerDirection="none">
              <IonIcon slot="start" color="secondary" icon={homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonAccordionGroup>
            <IonAccordion>
              <IonItem slot="header">
                <IonIcon slot="start" color="secondary" icon={cardOutline} />
                <IonLabel>Billing</IonLabel>
              </IonItem>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/billing/history" routerDirection="none">
                  <IonLabel>History</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/billing/payment" routerDirection="none">
                  <IonLabel>Payment</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonAccordion>
          </IonAccordionGroup>
          <IonAccordionGroup>
            <IonAccordion>
              <IonItem slot="header">
                <IonIcon slot="start" color="secondary" icon={peopleOutline} />
                <IonLabel>People</IonLabel>
              </IonItem>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/people/list" routerDirection="none">
                  <IonLabel>People</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/people/add" routerDirection="none">
                  <IonLabel>Add People</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/people/teams" routerDirection="none">
                  <IonLabel>Teams</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonAccordion>
          </IonAccordionGroup>
          <IonAccordionGroup>
            <IonAccordion>
              <IonItem slot="header">
                <IonIcon slot="start" color="secondary" icon={statsChartOutline} />
                <IonLabel>Usage</IonLabel>
              </IonItem>
              <IonMenuToggle slot="content">
                <IonItem className="indented-menu-item" button routerLink="/usage/quotas" routerDirection="none">
                  <IonLabel>Quotas</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonAccordion>
          </IonAccordionGroup>
        </IonList>

        <IonList className="floatBottom">
          <IonMenuToggle>
            <IonItem button routerLink="/settings" routerDirection="none">
              <IonIcon slot="start" color="secondary" icon={settingsOutline} />
              <IonLabel>Settings</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
