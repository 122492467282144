export interface PersonRow {
  firstName: string;
  lastName: string;
  email: string;
  language?: string;
}

export var placeholderPeopleData: PersonRow[] = [
  {
    firstName: 'Carlos',
    lastName: 'Juarez',
    email: 'Carlos.Juarez@example.com',
    language: 'Spanish',
  },
  {
    firstName: 'Steve',
    lastName: 'Smith',
    email: 'Steve.Smith@example.com',
    language: 'Spanish',
  },
];

export const getPlaceholderData = () => {
  return [...placeholderPeopleData];
};
