import PeopleListGrid from './PeopleListGrid';
import './PeopleListPanel.css';

interface PeopleListPanelProps {
  //onReset: () => void
}

const PeopleListPanel: React.FC<PeopleListPanelProps> = () => {
  return (
    <div>
      <div>{/* search bar and add person button go here */}</div>
      <div className="grid-container ion-margin-top">
        <PeopleListGrid />
      </div>
    </div>
  );
};

export default PeopleListPanel;
