import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  IonItem,
  IonButton,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonAlert,
  IonTitle,
} from '@ionic/react';
// import { Device, GetLanguageCodeResult } from '@capacitor/device';
import logo from '../../assets/images/app-logo.svg';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { syncUserID } from '../../utils/auth/AuthUtils';
import { AuthCache } from '../../utils/auth/AuthCache';
import { useTranslation } from 'react-i18next';
// import { useRecoilState, useSetRecoilState } from 'recoil';
// import { Language, Settings, settingsState } from '../settings/settingsState';
// import { getEnumKeyByEnumValue } from '../../utils/objectUtils';
// import i18n from '../../i18n';
import validator from 'validator';
import { navigateToRegistrationFromSigninFlag } from '../../flags';
// import VersionStamp from '../settings/VersionStamp';

/*
https://www.davidsalter.com/posts/creating-a-custom-login-page-with-aws-amplify/
https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/

Assumption is that any time they are validating they have an internet connection.
Currently, no "offline" authentication is allowed.

TODO: move some of the "low level" auth functionality out of this UI component into a lower level library such as AuthUtils?
 */
interface SignInProps {
  setIsShowRegistration: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignIn: React.FC<SignInProps> = ({ setIsShowRegistration }) => {
  // const [settings, setSettings] = useRecoilState(settingsState);
  const { t } = useTranslation();
  const emailInputRef = useRef<HTMLIonInputElement | null>(null);

  const [email, setEmail] = useState('');
  const [hideRequestOtp, setHideRequestOtp] = useState(false);
  const [otpConfirmation, setOtpConfirmation] = useState('');
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined);
  const [otpValidationFailed, setOtpValidationFailed] = useState(false);
  const [showNewCodeAlert, setShowNewCodeAlert] = useState<boolean>(false);

  // useEffect(() => {
  //   const setLanguageFromDeviceLanguage = async () => {
  //     try {
  //       const { value }: GetLanguageCodeResult = await Device.getLanguageCode();
  //       const twoLetterLanguageCode: string = value.slice(0, 2);
  //       const language: Language = Language[getEnumKeyByEnumValue(Language, twoLetterLanguageCode)];
  //       const updatedSettings: Settings = { ...settings, language };
  //       setSettings(updatedSettings);
  //       i18n.changeLanguage(language);
  //     } catch (error) {
  //       console.error('Failed to retrieve or change language', error);
  //     }
  //   };
  //   // This check exists so that we only set the language from the device language
  //   // if it has not been set previously. If it has been set previously, we use
  //   // the language setting that is saved in the app.
  //   if (settings.language === Language.UNDEFINED) {
  //     setLanguageFromDeviceLanguage();
  //   }
  // }, []);

  async function logOutOthers(user: CognitoUser) {
    /*
            TODO: implement signing out all the other instances of this user, except this instance
            According to some of the documentation this should work,
            others say the current refresh token is revoked, but the ID and access token are left valid:
            await Auth.signOut({global: true});
            but in practice it is also signing out the current user

            Theoretically buddy login prevention can be enforced using:
            user.forgetSpecificDevice
            user.listDevices()
 */
  }

  const verifyOtp = async () => {
    if (!email) {
      return;
    }
    if (!otpConfirmation) {
      return;
    }

    try {
      if (!cognitoUser) {
        console.error('No cognitoUser when attempting to verify OTP');
        throw new Error();
      }
      try {
        const user: CognitoUser | any = await Auth.sendCustomChallengeAnswer(cognitoUser, otpConfirmation);
        if (!(user instanceof CognitoUser)) {
          throw new Error('No CognitoUser returned');
        }

        //await Auth.rememberDevice();
        console.log('Refreshing the auth cache');
        await AuthCache.refresh();
        console.log('Logging out other users');
        await logOutOthers(user);

        console.log('Setting user in page state');
        setCognitoUser(user);
        console.log('Syncing userID');
        await syncUserID();
      } catch (exception) {
        console.error('Exception attempting verifyOtp', exception);
        throw new Error();
      }
    } catch (error) {
      setOtpValidationFailed(true);
    }
  };

  const requestOtp = async () => {
    if (!email) {
      return;
    }
    if (!validator.isEmail(email)) {
      console.log('This looks like a bogus email', email);
      //TODO: validation error?
    }

    setHideRequestOtp(true);

    Auth.signIn(email)
      .then(async (result: CognitoUser | any) => {
        if (!(result instanceof CognitoUser)) {
          console.error('No CognitoUser was returned from the signIn method, instead got the following:', result);
          throw new Error('No CognitoUser returned');
        }
        setCognitoUser(result);
      })
      .catch((e: any) => {
        if (e.code === 'UserNotFoundException') {
          //No User found, so signing up
          signUp();
        } else {
          console.error('Sign in error', e);
          setHideRequestOtp(false);
          alert('Failed to sign in');
        }
      });
  };

  const signUp = () => {
    Auth.signUp({
      username: email,
      password: 'a1A!' + Date.now().toString(), //random garbage, but matching the password policy
      attributes: {
        email: email,
      },
    })
      .then(async (result: ISignUpResult) => {
        console.log('Attempting to create data model user after signup');
        requestOtp(); //after signup then we attempt "sign in" again
      })
      .catch((e: any) => {
        if (e.code === 'UsernameExistsException') {
        } else {
          console.error('Sign up error', e);
          setHideRequestOtp(false);
          alert('Failed to sign up or sign in after sign up');
        }
      });
  };

  const requestNewOTP = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setOtpConfirmation('');
    setOtpValidationFailed(false);
    requestOtp();
    setShowNewCodeAlert(true);
    e.preventDefault();
  };

  const validateAndSetEmail = (emailAddress: string) => {
    setEmail(emailAddress);
  };

  const validateEmail = (emailAddress: string): boolean => {
    return validator.isEmail(emailAddress);
  };

  const handleEmailSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      emailInputRef.current?.setBlur(); // This is needed when submitting with the enter key to avoid a potential focus event when returning the app to the foreground
      requestOtp();
    } catch (error) {
      console.error('Failed to submit Email', error);
    }
  };

  const handleOTPSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      await verifyOtp();
    } catch (error) {
      console.error('Failed to submit OTP request', error);
    }
  };

  return (
    <>
      <IonGrid class="h-100 ion-padding-horizontal ion-align-items-center">
        <IonRow class="h-100 ion-justify-content-center ion-align-items-center">
          <IonCol size="12" sizeMd="10" sizeLg="6">
            <IonRow class="ion-justify-content-center">
              <IonCol size="3">
                <IonImg src={logo} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonTitle class="font-28 font-weight-700 ion-text-center ion-padding-vertical">VeriTask</IonTitle>
            </IonRow>
            <IonRow class="ion-justify-content-center">
              <IonCol size="12">
                <IonItem>
                  <form onSubmit={handleEmailSubmit} style={{ width: '100%' }}>
                    <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                      {t('email')}
                    </IonLabel>
                    <IonInput
                      class="centered-input"
                      ref={emailInputRef}
                      placeholder={t('placeholder.email')}
                      value={email}
                      type={'email'}
                      autocomplete="email"
                      autofocus={true}
                      onIonChange={(event) => validateAndSetEmail(event.detail.value?.trim() ?? '')}
                      onIonFocus={() => setHideRequestOtp(false)} // This functionality is per design
                    />
                  </form>
                </IonItem>
              </IonCol>
            </IonRow>
            {!hideRequestOtp ? (
              <>
                <IonRow>
                  <IonCol size="12">
                    <IonItem lines="none">
                      <IonText class="ion-text-center ion-margin-horizontal">
                        <h6>{t('otpPrompt')}</h6>
                      </IonText>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <IonCol size="12">
                    <IonButton color="primary" disabled={!validateEmail(email)} expand="block" onClick={requestOtp}>
                      {t('sendCode')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <>
                <IonRow>
                  <IonCol size="12">
                    <IonItem lines="none">
                      <IonText class="ion-text-center ion-margin-horizontal">
                        <h6>{t('otpSent')}</h6>
                      </IonText>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    <IonItem>
                      <form style={{ width: '100%' }} onSubmit={handleOTPSubmit}>
                        <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                          {t('otpCode')}
                        </IonLabel>
                        <IonInput
                          class="centered-input"
                          placeholder={t('placeholder.otp')}
                          value={otpConfirmation}
                          type={'number'}
                          pattern={'[0-9]*'}
                          autocomplete="one-time-code"
                          onIonChange={(e) => setOtpConfirmation(e.detail.value?.trim() ?? '')}
                        />
                      </form>
                    </IonItem>
                  </IonCol>
                </IonRow>
                {otpValidationFailed ? (
                  <IonRow>
                    <IonCol size="12">
                      <IonText color="danger">
                        <h6>{t('incorrectOTP')}</h6>
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : null}
                <IonRow class="ion-margin-vertical">
                  <IonCol size="12">
                    <IonButton color="primary" disabled={!otpConfirmation} expand="block" onClick={verifyOtp}>
                      {t('submit')}
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" class="ion-text-center">
                    <a href="#" onClick={(e) => requestNewOTP(e)}>
                      {t('newCodeRequest')}
                    </a>
                  </IonCol>
                </IonRow>
              </>
            )}
            {navigateToRegistrationFromSigninFlag ? (
              <IonRow>
                <IonCol size="12" class="ion-text-center">
                  <IonButton mode="ios" fill="clear" onClick={() => setIsShowRegistration(true)}>
                    Go To Registration Page
                  </IonButton>
                </IonCol>
              </IonRow>
            ) : null}
          </IonCol>
        </IonRow>
        {/* <IonRow class="ion-justify-content-center ion-align-items-center">
          <VersionStamp />
        </IonRow> */}
      </IonGrid>

      <IonAlert
        isOpen={showNewCodeAlert}
        onDidDismiss={() => setShowNewCodeAlert(false)}
        header={t('newVerificationCodeSent.header')}
        message={t('newVerificationCodeSent.message')}
        buttons={[
          {
            text: t('button.acknowledge'),
            handler: () => {
              setShowNewCodeAlert(false);
            },
          },
        ]}
      />
    </>
  );
};

export default SignIn;
