/**
 * https://stackoverflow.com/a/68533063/5423329
 */
export const getEnumKeyByEnumValue = <TEnumKey extends string, TEnumVal extends string | number>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal
): TEnumKey => {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter((x) => myEnum[x] === enumValue);
  if (keys.length !== 1) {
    throw new Error(`Could not find matching key in enum ${myEnum} for value ${enumValue}`);
  }
  return keys[0];
};
