import { ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { CognitoPropertyKeys, getCurrentUserAttributes } from './AuthUtils';

export class AuthCache {
  static cachedAttributes: ICognitoUserAttributeData[] | undefined;

  constructor() {
    AuthCache.refresh();
  }

  static async refresh() {
    await getCurrentUserAttributes(); //"getCurrentUserAttributes" automatically sets the cache
  }

  static async remoteRefresh(
    newAttributes: ICognitoUserAttributeData[] | undefined
  ) {
    AuthCache.cachedAttributes = newAttributes;
  }

  static async getCachedUserAttribute(
    attributeName: CognitoPropertyKeys
  ): Promise<string | undefined> {
    let result = undefined;
    if (!AuthCache.cachedAttributes) {
      await AuthCache.refresh();
    }
    if (AuthCache.cachedAttributes) {
      const filterItems = AuthCache.cachedAttributes.filter(
        (entry: ICognitoUserAttributeData) => {
          return entry.Name === attributeName;
        }
      );
      if (filterItems && filterItems.length === 1) {
        result = filterItems[0].Value;
      }
    }
    return result;
  }

  static invalidateCache() {
    AuthCache.cachedAttributes = undefined;
  }
}
