import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
// import LanguageSetting from './LanguageSetting';
import logo from '../../assets/images/app-logo.svg';
import React, { useEffect, useState } from 'react';
// import { useRecoilState } from "recoil";
// import { Settings, settingsState } from "./settingsState";
import { useTranslation } from 'react-i18next';
import {
  awsFormatPhone,
  CognitoPropertyKeys,
  forceRefreshCurrentSession,
  updateCurrentUserAttribute,
} from '../../utils/auth/AuthUtils';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthCache } from '../../utils/auth/AuthCache';
import useLogout from '../login/useLogout';
import { GetRegistrationStatus } from '../../utils/auth/RegistrationUtils';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

/*
https://github.com/bl00mber/react-phone-input-2
Should we use react-phone-number-input instead?
*/

interface RegistrationProps {
  isShowRegistration: boolean;
  setIsShowRegistration: React.Dispatch<React.SetStateAction<boolean>>;
}

const Registration: React.FC<RegistrationProps> = ({ isShowRegistration, setIsShowRegistration }) => {
  const { logOut } = useLogout();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [tempPhone, setTempPhone] = useState('');
  const [country, setCountry] = useState('us');
  // const [settings, setSettings] = useRecoilState(settingsState)

  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const onlyCountries = ['us', 'ca', 'au'];
  /*
    Since we are using a small list of whitelisted countries
    there is no need to configure explicit preferred and excluded countries.
    If we ever revert that decision these can be used to get us a more specific country list:

    const preferredCountries = ['us','ca'];
    const excludedCountries = [
        //Sanctioned, so we can not do business with them (as of 3/30/22 listed on https://www.bis.doc.gov/index.php/policy-guidance/country-guidance/sanctioned-destinations)
        'cu', 'ir', 'kp', 'sy',
        //Other unlikely or that cause validation issues
        'af','ru',
    ];
     */
  const reallyFarForward = 500;

  useEffect(() => {
    (async () => {
      console.log('Registration use effect');
      const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser();
      await GetRegistrationStatus(user); // sets Cognito attributes in cache

      let _name: string | undefined = await AuthCache.getCachedUserAttribute(CognitoPropertyKeys.name);
      let _phone: string | undefined = await AuthCache.getCachedUserAttribute(CognitoPropertyKeys.phone);
      //TODO: move this into the language selector or populate the language selector with this return value
      //let _language: string | undefined = await AuthCache.getCachedUserAttribute(CognitoPropertyKeys.language);

      //Initializing Name
      if (_name) setName(_name);
      //Initializing Phone
      if (_phone) {
        setTempPhone(_phone);
        setPhone(_phone);
      }
    })();
  }, []);

  function nameChange(newName: string) {
    setName(newName);
  }

  function isValidName(): boolean {
    const isEmpty: boolean = name === undefined || ('' + name).trim() === '' || ('' + name).trim().length === 0;

    if (isEmpty) {
      setNameErrorMessage(t('error.name.required'));
    }

    return !isEmpty;
  }

  function isValidPhone(): boolean {
    const phoneToValidate = ('' + tempPhone).trim();

    const isEmpty: boolean =
      phoneToValidate === undefined ||
      phoneToValidate === '' ||
      phoneToValidate === '1' || //just a US/Canada country code
      phoneToValidate === '61' || //just an AU country code
      phoneToValidate === '+' ||
      phoneToValidate === '+1' || //just a US/Canada country code
      phoneToValidate === '+61'; //just an AU country code

    /*
        TODO: more in depth format validation?
        Possibly using validator.isMobilePhone() or some other similar library
        const countriesToVerify:validator.MobilePhoneLocale[] = ['en-US','en-AU','en-CA','fr-CA'];
        console.log("Validating",phoneToValidate, "in country", country, "returns", validator.isMobilePhone(phoneToValidate, countriesToVerify));
        */

    if (isEmpty) {
      setPhoneErrorMessage(t('error.phone.required'));
      return false;
    }

    return true;
  }

  function phoneBlur(e: React.ChangeEvent<HTMLInputElement>, country: CountryData) {
    setCountry(country.countryCode);
  }

  function phoneChange(
    newPhone: string,
    country: CountryData,
    e: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) {
    setTempPhone(newPhone);
  }

  async function register() {
    let hasError: boolean = false;

    if (!isValidName()) {
      hasError = true;
    } else {
      setNameErrorMessage('');
    }

    if (!isValidPhone()) {
      hasError = true;
    } else {
      setPhoneErrorMessage('');
    }

    if (hasError) return;
    setPhone(tempPhone);

    // const updatedSettings: Settings = { ...settings, name: name, phone: tempPhone }
    // setSettings(updatedSettings)
    //await so that the identity token is properly updated on the session refresh
    await updateCurrentUserAttribute(CognitoPropertyKeys.name, name);
    //The state change to phone has not usually been processed by this point, so we use the value we wee setting it to rather than the actual value of the variable
    await updateCurrentUserAttribute(CognitoPropertyKeys.phone, awsFormatPhone(tempPhone));
    await forceRefreshCurrentSession(); //since we have update attributes we need those to reflect on the session
    await AuthCache.invalidateCache(); //dump the cache to get these updated attributes
  }

  const goBack = () => {
    if (isShowRegistration) {
      setIsShowRegistration(false);
    } else {
      logOut();
    }
  };

  return (
    <>
      <IonGrid class="h-100 ion-padding-horizontal d-flex flex-direction-column ion-justify-content-between">
        <IonRow>
          <IonCol>
            <IonList>
              <IonText>
                <h1>{t('registrationGreeting')}</h1>
              </IonText>
              <IonItem>
                <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                  {t('label.user.name')}*
                </IonLabel>
                <IonInput
                  class="centered-input"
                  name="name"
                  autocomplete="name"
                  value={name}
                  placeholder={t('placeholder.name')}
                  onIonChange={(event) => nameChange(event.detail.value?.trim() ?? '')}
                ></IonInput>
              </IonItem>
              <IonRow class="ion-no-margin">
                <IonCol>
                  <IonText color="danger">
                    <h6>{nameErrorMessage}</h6>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonItem
                style={{
                  overflow: 'visible',
                  zIndex: reallyFarForward,
                }}
              >
                {
                  //Most of the manual overflow on this page is a work around until we get this pull Request: https://github.com/bl00mber/react-phone-input-2/pull/507
                  //Another library this is based on has an option for using larger flags, though it requires a custom build of the library: https://codepen.io/jackocnr/full/ONXWgQ
                }
                <IonLabel
                  style={{
                    zIndex: reallyFarForward - 50,
                    fontSize: '30px',
                  }}
                  class="font-weight-700"
                  position="stacked"
                >
                  {t('phone')}*
                </IonLabel>
                {/*
                                    TODO: Adjust these styles even more based on theme?
                                    As is it works in both dark and light mode
                                    */}
                <PhoneInput
                  containerStyle={{
                    overflow: 'visible',
                    zIndex: reallyFarForward,
                  }}
                  dropdownStyle={{
                    left: '-26px', //helps clipping issues on certain phones.  We should probably tweak width instead
                    zIndex: reallyFarForward,
                    fontSize: '24px',
                    color: 'black',
                  }}
                  inputStyle={{
                    fontSize: '16px',
                    width: '100%',
                    color: 'black',
                  }}
                  searchStyle={{
                    fontSize: '16px',
                  }}
                  buttonStyle={{}}
                  country={country}
                  value={phone}
                  onChange={phoneChange}
                  onBlur={phoneBlur}
                  //placeholder={"+1 (555) 555-5555"}
                  onlyCountries={onlyCountries}
                  //preferredCountries={preferredCountries}
                  //excludeCountries={excludedCountries}
                  //enableSearch={true}//needed for a long list of countries
                  countryCodeEditable={false}
                />
              </IonItem>
              <IonRow>
                <IonCol>
                  <IonText color="danger">
                    <h6>{phoneErrorMessage}</h6>
                  </IonText>
                </IonCol>
              </IonRow>
              <br />
              {/* <LanguageSetting /> */}
              <IonRow class="ion-margin-vertical ion-justify-content-center ion-align-items-center">
                <IonCol size="4" class="ion-text-center">
                  <a href="#" onClick={goBack}>
                    {t('cancel')}
                  </a>
                </IonCol>
                <IonCol size="8">
                  <IonButton expand="block" onClick={register}>
                    {t('submit')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center ion-align-items-end">
          <IonCol size="2" class="ion-text-center">
            <IonImg src={logo} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Registration;
