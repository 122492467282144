import { IonCard, IonCardHeader, IonContent, IonHeader, IonLabel } from '@ionic/react';

import './PeopleImportInstructionPanel.css';
import { URL_PATH_PREFIX } from '../../../../utils/Globals';

const PeopleImportInstructionPanel: React.FC = (props: any) => {
  return (
    <IonCard className="add-people-instructions">
      <IonCardHeader color="primary">
        <IonLabel>Instructions</IonLabel>
      </IonCardHeader>
      <ul>
        Method 1<li>Prepare a spreadsheet with your employee information.</li>
        <li>Ensure columns match the order displayed above.</li>
        <li>Select the cells you want to copy from your spreadsheet and copy them (Windows Ctrl-C, Mac Cmd-C)</li>
        <li>On this page, click on the cell in the top left and paste! (Windows Ctrl-V, Mac Cmd-V)</li>
        <li>
          Problems (incorrect email format, invalid languages, etc) will appear <span className="invalid">red</span>
        </li>
        <li>If there are no issues, or once they are resolved, press the "IMPORT" button.</li>
        <li>You can press "CLEAR/RESET" to start over.</li>
      </ul>

      <ul>
        Method 2<li>Enter your employee data on this page, as you would if you were entering it into a spreadsheet.</li>
        <li>
          Problems (incorrect email format, invalid languages, etc) will appear <span className="invalid">red</span>
        </li>
        <li>If there are no issues, or once they are resolved, press the "IMPORT" button.</li>
        <li>You can press "CLEAR/RESET" to start over.</li>
      </ul>
    </IonCard>
  );
};

export default PeopleImportInstructionPanel;
