import * as wjcCore from '@grapecity/wijmo';
import { ImmutabilityProvider } from '@grapecity/wijmo.react.grid.immutable';
import { FlexGrid } from '@grapecity/wijmo.react.grid';

export const gridValidator = (row: number, col: number, parsing: boolean, ipRef: ImmutabilityProvider | null, gridRef: FlexGrid | null, flagError: any, clearError: any) => {
  const pos = [row, col].join(',');

  let grid = ipRef?.control.itemsSource;
  let item = grid[row];

  let header = gridRef?.control.columns[col].header;
  let it = gridRef?.control.rows[row].dataItem;
  let prop = gridRef?.control.columns[col].binding;

  if (isRequired(prop)) {
    if (it) {
      if (wjcCore.isUndefined(it[prop])) {
        flagError(pos);
        return header + ' is required.';
      }
      const str = wjcCore.changeType(it[prop], wjcCore.DataType.String);
      if (wjcCore.isNullOrWhiteSpace(str)) {
        flagError(pos);
        return header + ' is required.';
      }
    }
  }

  if (prop === 'email' && item && item.email) {
    const str = wjcCore.changeType(item.email, wjcCore.DataType.String);
    if (!validateEmail(str)) {
      flagError(pos);
      return 'Valid email address required';
    }
  }

  if (prop === 'language' && item && item.language) {
    const str = wjcCore.changeType(item.language, wjcCore.DataType.String);
    if (!validateLanguage(str)) {
      flagError(pos);
      return 'Only English and Spanish are currently supported.';
    }
  }

  // We've reached the bottom without flagging/returning any errors, therefore this cell is OK
  clearError(pos);
};

const isRequired = (prop: string) => {
  return prop === 'firstName' || prop === 'lastName' || prop === 'email';
};

const validateEmail: any = (email: string) => {
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

const validateLanguage: any = (language: string) => {
  const str = language.toLowerCase();
  return str === 'english' || str === 'en' || str === 'spanish' || str === 'es';
};
