import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { useParams } from "react-router";
import PeopleImportPanel from '../features/people/components/PeopleImportGrid/PeopleImportPanel';
import Toolbar from '../features/toolbar/Toolbar';

interface AddPeopleProps {

}

const AddPeoplePage: React.FC<AddPeopleProps> = (props) => {
  // const { name } = useParams<{ name: string; }>()
  const title = 'People'

  return (
    <IonPage>
      <Toolbar title={title} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <PeopleImportPanel />
      </IonContent>
    </IonPage>
  )
}

export default AddPeoplePage