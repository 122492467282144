import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPopover,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { notificationsOutline } from 'ionicons/icons';
import { URL_PATH_PREFIX } from '../../utils/Globals';
import UserMenu from '../user/components/UserMenu/UserMenu';

import './Toolbar.css';

interface PageHeaderProps {
  title: string;
}

const Toolbar: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <IonHeader>
      <IonToolbar color="secondary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <div className="search-bar-container-flex" slot="end">
          <IonSearchbar className="search-bar" />
          <IonImg
            id="user-menu-trigger"
            className="avatar"
            src={'./' + (URL_PATH_PREFIX === '' ? '' : (URL_PATH_PREFIX + "/")) + 'assets/img/profile.png'}
            alt="profile avatar"
          />
          <IonPopover trigger="user-menu-trigger" showBackdrop={false}>
            <IonContent class="ion-padding">
              <UserMenu />
            </IonContent>
          </IonPopover>
          <IonIcon className="notification-button" color="light" icon={notificationsOutline}></IonIcon>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Toolbar;
