import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import PeopleListPanel from '../features/people/components/PeopleListPanel/PeopleListPanel';
import Toolbar from '../features/toolbar/Toolbar';

interface PeopleListProps {}

const PeopleListPage: React.FC<PeopleListProps> = (props) => {
  const { t } = useTranslation();

  const title = t('people');

  return (
    <IonPage>
      <Toolbar title={title} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <PeopleListPanel />
      </IonContent>
    </IonPage>
  );
};

export default PeopleListPage;
