import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
} from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, UserRole } from '../../../../constants/constants';
import { Person } from '../../peopleApi';
import './EditPersonDialog.css';

interface EditPersonDialogProps {
  dismiss: () => void;
  person: Person;
  savePerson: (updatedPerson: Person) => void;
  managers: Person[];
}

const EditPersonDialog: React.FC<EditPersonDialogProps> = ({ dismiss, person, savePerson, managers }) => {
  const [firstName, setFirstName] = useState<string | undefined>(person.firstName);
  const [lastName, setLastName] = useState<string | undefined>(person.lastName);
  const [email, setEmail] = useState<string>(person.email);
  const [role, setRole] = useState<UserRole>(person.role);
  const [language, setLanguage] = useState<Language>(person.language);
  const [manager, setManager] = useState<Person | null | undefined>(person.manager);

  const { t } = useTranslation();

  const makeManagerList = (): JSX.Element[] => {
    const isCurrentPersonToEdit = (userId: string): boolean => userId === person.userId;
    const possibleManagers = managers.filter((manager) => !isCurrentPersonToEdit(manager.userId));
    return possibleManagers.map(({ userId, firstName, lastName }) => (
      <IonSelectOption key={userId} value={userId}>
        {`${firstName ?? ''} ${lastName ?? ''}`}
      </IonSelectOption>
    ));
  };

  const handleSubmit = (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    event.preventDefault();
    save();
  };

  const save = () => {
    const updatedPerson: Person = {
      ...person,
      firstName,
      lastName,
      email,
      role,
      language,
      manager: manager ?? undefined,
    };
    savePerson(updatedPerson);
    dismiss();
  };

  const selectManager = (managerId: string | null) => {
    if (!managerId) {
      setManager(null);
    } else {
      const newManager: Person | undefined = managers.find((m) => m.userId === managerId);
      setManager(newManager);
    }
  };

  return (
    <>
      <IonHeader>
        <IonTitle>Edit Person</IonTitle>
      </IonHeader>
      <IonContent>
        <IonList>
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.firstName')}</IonLabel>
              <IonInput
                value={firstName}
                placeholder={t('placeholder.firstName')}
                autocomplete="given-name"
                onIonChange={(event) => setFirstName(event.detail.value ?? undefined)}
              />
            </IonItem>
          </form>
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.lastName')}</IonLabel>
              <IonInput
                value={lastName}
                placeholder={t('placeholder.lastName')}
                autocomplete="family-name"
                onIonChange={(event) => setLastName(event.detail.value ?? undefined)}
              />
            </IonItem>
          </form>
          <form onSubmit={handleSubmit}>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.email')}</IonLabel>
              <IonInput
                value={email}
                placeholder={t('placeholder.email')}
                autocomplete="email"
                onIonChange={(event) => setEmail(event.detail.value ?? '')}
              />
            </IonItem>
          </form>
          <div>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.language')}</IonLabel>
              <IonSelect
                value={language}
                onIonChange={(event) => setLanguage(event.detail.value)}
                onIonCancel={() => setLanguage(person.language)}
              >
                <IonSelectOption value={Language.ENGLISH}>{t(`language.name.${Language.ENGLISH}`)}</IonSelectOption>
                <IonSelectOption value={Language.ESPAÑOL}>{t(`language.name.${Language.ESPAÑOL}`)}</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
          <div>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.role')}</IonLabel>
              <IonSelect
                value={role}
                onIonChange={(event) => setRole(event.detail.value)}
                onIonCancel={() => setRole(person.role)}
              >
                <IonSelectOption value={UserRole.EMPLOYEE}>Employee</IonSelectOption>
                <IonSelectOption value={UserRole.MANAGER}>Manager</IonSelectOption>
                <IonSelectOption value={UserRole.ADMIN}>Admin</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
          <div>
            <IonItem>
              <IonLabel position="stacked">{t('label.user.manager')}</IonLabel>
              <IonSelect
                value={manager?.userId}
                placeholder="Choose a Manager"
                onIonChange={(event) => selectManager(event.detail.value)}
                onIonCancel={() => setManager(person.manager)}
              >
                <IonSelectOption value={null}>None</IonSelectOption>
                {makeManagerList()}
              </IonSelect>
            </IonItem>
          </div>
        </IonList>
        <div className="d-flex ion-justify-content-end">
          <IonButton color={'medium'} onClick={dismiss}>
            Cancel
          </IonButton>
          <IonButton onClick={save}>Save</IonButton>
        </div>
      </IonContent>
    </>
  );
};

export default EditPersonDialog;
