import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import Toolbar from '../features/toolbar/Toolbar';

interface HomeProps {}

const HomePage: React.FC<HomeProps> = (props) => {
  // const { name } = useParams<{ name: string; }>()
  const title = 'Home';

  return (
    <IonPage>
      <Toolbar title={title} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>Content here</div>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
