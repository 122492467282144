import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/utilityClasses.css';
import HomePage from './pages/Home';
import BillingHistoryPage from './pages/BillingHistoryPage';
import BillingPaymentPage from './pages/BillingPaymentPage';
import PeopleListPage from './pages/PeopleListPage';
import AddPeoplePage from './pages/AddPeoplePage';
import TeamsPage from './pages/TeamsPage';
import UsageQuotasPage from './pages/UsageQuotasPage';
import SettingsPage from './pages/Settings';
import * as wjcCore from '@grapecity/wijmo';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { URL_PATH_PREFIX } from './utils/Globals';

Amplify.configure(awsconfig);

setupIonicReact();

const App: React.FC = () => {
  wjcCore.setLicenseKey(
    'admin.veritaskapp.com|admin2.veritaskapp.com|localhost|admin2.veritaskapp.com.s3-website-us-east-1.amazonaws.com|admin.veritaskapp.com.s3-website-us-west-2.amazonaws.com,379636618582852#B0SIKIyazFGVpJXZWJiOiEmTDJCLiITN8IDO5gTM6YzM6kzNzIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVzLIJ6RVVkWPdWbQd5ZsZVWEdUNt9UOXFFMYNWWvQmNQZ5TvMENuxEaSxEe9dXM4NVZZ3WRNlEOy44Qz8WdvoGeWl6VvQVZsFFcTFnT9JleJZ7aq3ySQd7RxdFREhjQwU7KJFFODtiNvhEbmh5dHtkMWdVd0l5bBZkMzR6bj96c5UmaNxUOsl4THZkVvYWUslTWRJ7Qrc4Mmh6LYRmbudWT6QXVUdjewgFWNx4dw8mZaFENGZzRNZFVkVVZzBDRR3mUtJXYZNUaqNDd8Y6akJXRjd4TVlEep3kRR5UN5BzQ4EFRLl6KFFFUndke4kHTu3CZThnNNxGUudnMMdHNIJlWVRjWNBVTVJFbLljezd5RwIFdNlUOTZjTUtWcU3WTwckTCN5RpJDckllNBZ5KzBHeWZjbW3WcxY6MEF5cJl6NmVTW6Q5KuN4RvBDU8VWMEdUZMF4YiojITJCLiQUM4IEOFFDNiojIIJCL7cDMyIDOyAjM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiIDMxQzMwASMyETMyIDMyIiOiQncDJCLi46bj9yc7FmbvpXYtFmLy4CdzV6dtMXdtUGdpNnYldXLzMnLt36YuAHcht6chRXayVmdu8WatRWYs46bj9yc7FmbvpXYtFmLx4CdzFWZtMXdtUGdpNnYldXLzMnLt36YuAHcht6chRXayVmduIjbp5GZhxCdz3GasF6YvxGLt36YuAHcht6chRXayVmduIjbp5GZhxSbvNmLwBXYrNXY4lmclZnLulWbkFmI0ITcYR'
  );

  return (
    <IonApp>
      {/* <IonReactRouter basename={'/' + URL_PATH_PREFIX}> */}
      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" exact={true}>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact={true}>
            <HomePage />
          </Route>
          <Route path="/billing/history" exact={true}>
            <BillingHistoryPage />
          </Route>
          <Route path="/billing/payment" exact={true}>
            <BillingPaymentPage />
          </Route>
          <Route path="/people/list" exact={true}>
            <PeopleListPage />
          </Route>
          <Route path="/people/add" exact={true}>
            <AddPeoplePage />
          </Route>
          <Route path="/people/teams" exact={true}>
            <TeamsPage />
          </Route>
          <Route path="/usage/quotas" exact={true}>
            <UsageQuotasPage />
          </Route>
          <Route path="/settings" exact={true}>
            <SettingsPage />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
