import { IonButton, IonLabel } from '@ionic/react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import LoadingMessage from '../../../../components/LoadingMessage';
import { Language } from '../../../../constants/constants';
import { gridErrorState, PersonRow, teamGridData } from '../../../../data/useTeamGridData';
import { putPeople, PutPersonInput } from '../../peopleApi';
import { getPlaceholderData } from './peopleGridData';
import PeopleImportGrid from './PeopleImportGrid';
import PeopleImportInstructionPanel from './PeopleImportInstructionPanel';
import './PeopleImportPanel.css';

interface PeopleImportPanelProps {
  //onReset: () => void
}

const PeopleImportPanel: React.FC<PeopleImportPanelProps> = () => {
  const [gridState, setGridState] = useRecoilState<PersonRow[]>(teamGridData);
  const hasErrors = useRecoilState(gridErrorState);
  const [isProcessing, setIsProcessing] = useState(false);

  const importPeople = () => {
    setIsProcessing(true);
    const people: PutPersonInput[] = gridState.map((p) => {
      return {
        ...p,
        language: mapLanguage(p.language),
      };
    });
    putPeople(people)
      .then((result) => {
        resetGrid();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const mapLanguage = (lang: string | undefined) => {
    if (lang === 'undefined' || lang === null || lang === '') {
      return Language.UNDEFINED;
    }

    const l = lang?.toLowerCase();
    if (l === 'en' || l === 'english') {
      return Language.ENGLISH;
    } else if (l === 'es' || l === 'spanish') {
      return Language.ESPAÑOL;
    } else {
      return Language.UNDEFINED;
    }
  };

  const resetGrid = () => {
    const gridData = getPlaceholderData();
    setGridState(gridData);
  };

  const importButtonLabel = gridState.length === 0 ? 'Import' : 'Import ' + gridState.length + ' Users';

  return (
    <div>
      {isProcessing ? <LoadingMessage /> : <></>}
      <div>
        <IonLabel>Paste in your Employees and Managers Here:</IonLabel>
      </div>
      <div className="grid-container">
        <PeopleImportGrid />
        <div className="grid-buttons">
          <IonButton className="btn-reset" fill="outline" onClick={resetGrid}>
            Clear/Reset
          </IonButton>
          <IonButton className="btn-import" disabled={!hasErrors} onClick={importPeople}>
            {importButtonLabel}
          </IonButton>
        </div>
      </div>
      <PeopleImportInstructionPanel />
    </div>
  );
};

export default PeopleImportPanel;
