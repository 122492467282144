import React, { useEffect, useState } from 'react';

import { Amplify, Auth, Hub } from 'aws-amplify';
import SignIn from './features/login/SignIn';
import App from './App';
import awsconfig from './aws-exports';
import { CognitoUser } from 'amazon-cognito-identity-js';
import LoadingMessage from './components/LoadingMessage';
import { IonContent, IonPage } from '@ionic/react';
import { HubCallback, HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import Registration from './features/login/Registration';
import { GetRegistrationStatus } from './utils/auth/RegistrationUtils';

Amplify.configure(awsconfig); //in order to implement basic GDPR compliance by changing Regions the data is stored in we can pass in different configurations to this (one for US or one for Europe, etc).
//Amplify.Logger.LOG_LEVEL = 'DEBUG'

enum LoginStatus {
  LOADING,
  LOGGED_IN,
  NOT_LOGGED_IN,
}

const SecureApp: React.FC = () => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.LOADING);
  const [registered, setRegistered] = useState<boolean | undefined>(undefined);
  const [isShowRegistration, setIsShowRegistration] = useState<boolean>(false);

  useEffect(() => {
    Hub.listen('auth', authListener);
    checkStatus();
    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  const checkStatus = async () => {
    setRegistered(undefined);
    setLoginStatus(LoginStatus.LOADING);
    const user: CognitoUser | undefined = await getLoginStatus();
    const looksRegistered: boolean = await GetRegistrationStatus(user);
    setRegistered(looksRegistered);
  };

  const getLoginStatus = async (): Promise<CognitoUser | undefined> => {
    let result: LoginStatus = LoginStatus.NOT_LOGGED_IN;
    let user: CognitoUser | undefined = undefined;

    try {
      user = await Auth.currentAuthenticatedUser();

      if (user && user.getUsername() !== '') {
        result = LoginStatus.LOGGED_IN;
      }
    } catch (exception) {
      if (exception === 'The user is not authenticated') {
        result = LoginStatus.NOT_LOGGED_IN;
      } else if (exception === 'No current user') {
        result = LoginStatus.NOT_LOGGED_IN;
      } else {
        console.error('Unhandled exception getting current user while checking login status', exception);
        result = LoginStatus.NOT_LOGGED_IN;
      }
    }
    setLoginStatus(result);
    return user;
  };

  const authListener: HubCallback = async (data: HubCapsule) => {
    switch (
      data.payload.event // See https://docs.amplify.aws/guides/authentication/listening-for-auth-events/q/platform/js/ for list of auth events
    ) {
      case 'configured':
        //intentionally do nothing.  This is called under the hood when authentication is initialized
        break;
      case 'signIn':
        checkStatus(); //Once they log in we want to "freshen" our status flags
        break;
      case 'signOut':
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
        break;
      case 'tokenRefresh':
        //Huzzah on refreshing the token.  There is nothing to do about it, except make sure the user is registered
        checkStatus();
        break;
      case 'signIn_failure':
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
        break;
      case 'signUp':
        //This is ok during the signup process
        break;
      case 'tokenRefresh_failure':
        if (loginStatus === LoginStatus.NOT_LOGGED_IN) {
          //It looks like we are getting these errors on the sign-in page, which is ok when there is not a current user/session to refresh
        } else {
          console.error('Signed in but also token refresh failure');
          //TODO: Find out what is causing these and whether it is critical.  They may be harmless
        }
        break;
      default:
        console.warn('Detected unhandled Authentication event', data.payload.event, data);
        break;
    }
  };

  const retComponent = (): JSX.Element => {
    if (isShowRegistration) {
      return <Registration isShowRegistration={isShowRegistration} setIsShowRegistration={setIsShowRegistration} />;
    } else if (loginStatus === LoginStatus.LOADING) {
      return <LoadingMessage />;
    } else if (loginStatus === LoginStatus.NOT_LOGGED_IN) {
      return <SignIn setIsShowRegistration={setIsShowRegistration} />;
    } else {
      if (registered === undefined) {
        return <LoadingMessage />;
      } else if (registered === false) {
        return <Registration isShowRegistration={isShowRegistration} setIsShowRegistration={setIsShowRegistration} />;
      } else {
        return <App />;
      }
    }
  };

  return (
    <IonPage>
      <IonContent>{retComponent()}</IonContent>
    </IonPage>
  );
};

export default SecureApp;
