// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      "addFirstList": "Tap the \"+\" <br /> to add a list",
      "addFirstTask": "Tap the \"+\" <br /> to add a task",
      "addList": "Add List",
      "addTask": "Add Task",
      "button.acknowledge": "OK",
      "button.delete": "Delete",
      "button.edit": "Edit",
      "cancel": "Cancel",
      "complete": "Complete",
      "completed": "Completed",
      "completeTask": "Complete Task",
      "completeTasks": "Complete Tasks",
      "completeList": "Complete List",
      "confirmation.delete.image.confirm": "Delete image",
      "confirmation.delete.image.header": "Delete Image",
      "confirmation.delete.image.message": "You will not be able to recover this image once deleted.",
      "confirmation.delete.list.confirm": "Delete the list",
      "confirmation.delete.list.header": "Delete this list?",
      "confirmation.delete.list.message": "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.person.confirm": "Yes, delete this person",
      "confirmation.delete.person.header": "Delete Person?",
      "confirmation.delete.person.message": "Are you sure?",
      "confirmation.delete.task.confirm": "Delete task",
      "confirmation.delete.task.header": "Delete Task",
      "confirmation.delete.task.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.delete.task.generic.confirm": "Delete",
      "confirmation.delete.task.generic.header": "Confirm Deletion",
      "confirmation.delete.task.generic.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "contactSupport": "Contact Support",
      "darkMode": "Dark Mode",
      "delete": "Delete",
      "deleteTasks": "Delete Tasks",
      "deselectAll": "Deselect All",
      "done": "Done",
      "duplicate": "Duplicate",
      "duplicateTask": "Duplicate Task",
      "duplicateTasks": "Duplicate Tasks",
      "duplicateList": "Duplicate List",
      "edit": "Edit",
      "email": "Email",
      "enum.status.active": "Active",
      "enum.status.inactive": "Inactive",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Employee",
      "enum.userRole.manager": "Manager",
      "enum.userRole.super_admin": "Super-Admin",
      "error.name.required": "Name is Required",
      "error.phone.invalid": "Phone appears to be invalid",
      "error.phone.required": "Phone is Required",
      "errorAlert.header": "Whoops!",
      "errorAlert.message": "We ran into an error on our side. Sorry about that! If it happens multiple times, please feel free to <a href='https://hi.volley.app/land?tk=FmUR69YRUJjGkx2jqD4NJ8-tk'>Contact Support</a>.",
      "errorPage": "Hi, I'm the Error Page.",
      "errorPage.backIntoIt": "Let's get back into it.",
      "errorPage.sorry": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "feedback.list.completed": "Great work! You've completed the list.",
      "feedback.task.completed": "Great work! Task completed: \"{{taskName}}\"",
      "home": "Home",
      "incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "label.selectAllCompletedTasks": "Select all completed tasks",
      "label.selectAllTasks": "Select all tasks",
      "label.task.instructions": "Instructions",
      "label.task.name": "Task",
      "label.task.taskName": "Task",
      "label.task.notes": "Notes",
      "label.user.actions": "Actions",
      "label.user.active": "Active (Billable)",
      "label.user.email": "Email",
      "label.user.firstName": "First Name",
      "label.user.language": "Language",
      "label.user.lastName": "Last Name",
      "label.user.manager": "Manager",
      "label.user.name": "Name",
      "label.user.role": "Role",
      "language": "Language",
      "language.name.en": "English",
      "language.name.es": "Spanish",
      "listName": "Name of List",
      "list.rename": "Rename List",
      "lists": "Lists",
      "loading": "Loading...",
      "logout": "Log out",
      "newCodeRequest": "Send me a new code",
      "newVerificationCodeSent.header": "We've sent the code.",
      "newVerificationCodeSent.message": "Please check your email inbox for the new code. It may have landed in your junk folder. It does that sometimes.",
      "offlineNoticeHeader": "No internet :(",
      "offlineNoticeMessage": "We should reconnect soon. To find out if it's not just our app, you can... <br/> <br/> <ul><li> Check your phone settings</li><li>Check other Apps</li><li>Check WiFi signal strength</li></ul><br/>And don't worry we save your work as we go!",
      "otpCode": "Code",
      "otpPrompt": "We'll send a code to your email so you can start using VeriTask",
      "otpSent": "We've sent a verification code to your email. Please enter it below.",
      "people": "People",
      "phone": "Phone",
      "picturesAbbreviated": "Pics",
      "placeholder.email": "jane.doe@example.com",
      "placeholder.firstName": "Jane",
      "placeholder.generic": "Tap to enter text",
      "placeholder.language": "Select language",
      "placeholder.lastName": "Doe",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Your verification code",
      "product.version": "v.",
      "registrationGreeting": "Welcome! Let's finish setting up your account...",
      "requestToWait": "Please wait...",
      "select": "Select",
      "selectAll": "Select All",
      "sendCode": "Send Verification Code",
      "settings": "Settings",
      "submit": "Submit",
      "tasks": "Tasks",
      "undefinedList.name": "Undefined List",
      "wifiOnly": "Use Wifi Only",
      "wifiOnlyHint": "If you want the app to use your cell phone data, leave this option disabled. If you want to preserve your cell data, enable it.",
    }
  },
  es: {
    translation: {
      "addFirstList": "Presione \"+\" <br /> para agregar una lista",
      "addFirstTask": "Presione \"+\" <br /> para agregar una tarea",
      "addList": "Agregar Lista",
      "addTask": "Agregar Tarea",
      "button.acknowledge": "Aceptar",
      "cancel": "Cancelar",
      "complete": "Completar",
      "completed": "Completadas",
      "completeTask": "Completar Tarea",
      "completeTasks": "Completar Tareas",
      "completeList": "Completar Lista",
      "confirmation.delete.image.confirm": "Eliminar imagen",
      "confirmation.delete.image.header": "Eliminar Imagen",
      "confirmation.delete.image.message": "No podrá recuperar esta imagen una vez eliminada.",
      "confirmation.delete.list.confirm": "Eliminar la lista",
      "confirmation.delete.list.header": "¿Eliminar esta lista?",
      "confirmation.delete.list.message": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.task.confirm": "Eliminar tarea",
      "confirmation.delete.task.header": "Eliminar Tarea",
      "confirmation.delete.task.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.delete.task.generic.confirm": "Eliminar",
      "confirmation.delete.task.generic.header": "Confirmar Eliminación",
      "confirmation.delete.task.generic.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "contactSupport": "Contactar a Soporte",
      "darkMode": "Modo Oscuro",
      "delete": "Eliminar",
      "deleteTasks": "Eliminar Tareas",
      "deselectAll": "Deseleccionar Todo",
      "done": "Hecho",
      "duplicate": "Duplicar",
      "duplicateTask": "Duplicar Tarea",
      "duplicateTasks": "Duplicar Tareas",
      "duplicateList": "Duplicar Lista",
      "edit": "Editar",
      "email": "Correo electrónico",
      "error.name.required": "El nombre es obligatorio",
      "error.phone.invalid": "El teléfono parece no ser válido",
      "error.phone.required": "El teléfono es obligatorio",
      "errorAlert.header": "Ups!",
      "errorAlert.message": "Nos encontramos con un error de nuestro lado. ¡Lo lamentamos! Si sucede varias veces, no dude en <a href='https://hi.volley.app/land?tk=FmUR69YRUJjGkx2jqD4NJ8-tk'>Ponerse en Contacto con el Soporte</a>.",
      "errorPage": "Hola, soy la Página de Error.",
      "errorPage.backIntoIt": "Retomemos.",
      "errorPage.sorry": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "feedback.list.completed": "¡Buen trabajo! Ha completado la lista.",
      "feedback.task.completed": "¡Buen trabajo! Tarea completada: \"{{taskName}}\"",
      "home": "Inicio",
      "incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "label.selectAllCompletedTasks": "Seleccionar todas las tareas completadas",
      "label.selectAllTasks": "Seleccionar todas las tareas",
      "label.task.instructions": "Instrucciones",
      "label.task.name": "Tarea",
      "label.task.taskName": "Tarea",
      "label.task.notes": "Notas",
      "label.user.name": "Nombre",
      "language": "Idioma",
      "language.name.en": "Inglés",
      "language.name.es": "Español",
      "listName": "Nombre de la Lista",
      "list.rename": "Cambiar Nombre",
      "lists": "Listas",
      "loading": "Cargando...",
      "logout": "Cerrar sesión",
      "newCodeRequest": "Enviarme un nuevo código",
      "newVerificationCodeSent.header": "Hemos enviado el código.",
      "newVerificationCodeSent.message": "Revise su bandeja de entrada para obtener el nuevo código. Es posible que haya llegado a su carpeta de correo no deseado. A veces, pasa eso.",
      "offlineNoticeHeader": "Sin internet :(",
      "offlineNoticeMessage": "Deberíamos reconectarnos pronto. Para saber si no es solo nuestra aplicación, puede... <br/><br/><ul><li>Verificar la configuración de su teléfono</li><li>Verificar otras aplicaciones</li><li>Verificar la intensidad de la señal wifi</li></ul><br/>Y no se preocupe, ¡guardamos su trabajo a medida que avanzamos!",
      "otpCode": "Código",
      "otpPrompt": "Le enviaremos un código a su correo electrónico para que pueda comenzar a usar VeriTask",
      "otpSent": "Hemos enviado un código de verificación a su correo electrónico. Ingréselo a continuación.",
      "phone": "Teléfono",
      "picturesAbbreviated": "Fotos",
      "placeholder.email": "maria.sanchez@example.com",
      "placeholder.generic": "Tocar para ingresar texto",
      "placeholder.name": "María Sánchez",
      "placeholder.otp": "Su código de verificación",
      "product.version": "v.",
      "registrationGreeting": "¡Bienvenido! Terminemos de configurar su cuenta...",
      "requestToWait": "Espere...",
      "select": "Seleccionar",
      "selectAll": "Seleccionar Todo",
      "sendCode": "Enviar Código de Verificación",
      "settings": "Ajustes",
      "submit": "Enviar",
      "tasks": "Tareas",
      "undefinedList.name": "Undefined List",
      "wifiOnly": "Usar Solo Wifi",
      "wifiOnlyHint": "Si desea que la aplicación use los datos de su teléfono celular, deje esta opción desactivada. Si desea conservar los datos de su celular, actívela.",
    }
  }
}

export default resources
