import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoPropertyKeys, getCurrentUserAttributes } from './AuthUtils';
import { AuthCache } from './AuthCache';

export const GetRegistrationStatus = async (user: CognitoUser | undefined): Promise<boolean> => {
  try {
    if (!user) {
      return false;
    }
  } catch (exception) {
    return false;
  }
  await getCurrentUserAttributes(); //Temporary work around for CC-443
  let name: string | undefined = await AuthCache.getCachedUserAttribute(CognitoPropertyKeys.name);
  let phone: string | undefined = await AuthCache.getCachedUserAttribute(CognitoPropertyKeys.phone);
  //language always defaults so there is not need to check it explicitly.

  const isRegistered: boolean =
    true && name !== undefined && name.trim() !== '' && phone !== undefined && phone.trim() !== '';
  return isRegistered;
};
