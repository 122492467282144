import { Language, Status, UserRole } from '../../../../constants/constants';
import { getEnumKeyByEnumValue } from '../../../../utils/objectUtils';
import './PeopleListGrid.css';
import { IonButton, IonRow, useIonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import EditPersonDialog from '../EditPersonDialog/EditPersonDialog';
import { useTranslation } from 'react-i18next';
import DeleteConfirmation from '../../../../components/DeleteConfirmation';
import { deletePerson, editPerson, EditPersonInput, getActivePeople, Person } from '../../peopleApi';
import LoadingMessage from '../../../../components/LoadingMessage';

const headers = [
  'label.user.firstName',
  'label.user.lastName',
  'label.user.email',
  'label.user.language',
  'label.user.role',
  'label.user.manager',
  'label.user.active',
];

const placeholderPerson: Person = {
  userId: '-1',
  email: '',
  language: Language.ENGLISH,
  role: UserRole.EMPLOYEE,
  status: Status.ACTIVE,
};

interface PeopleListGridProps {}

const PeopleListGrid: React.FC<PeopleListGridProps> = (props) => {
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [people, setPeople] = useState<Person[]>([]);
  const [currentPersonToEdit, setCurrentPersonToEdit] = useState<Person>(placeholderPerson);
  const [currentPersonToDelete, setCurrentPersonToDelete] = useState<Person>();

  const { t } = useTranslation();

  useEffect(() => {
    getActivePeople().then((fetchedPeople) => {
      setPeople(fetchedPeople);
      setIsDataFetched(true);
      document.querySelectorAll('ion-loading').forEach((loading) => loading.remove()); // The loading elements should be removed when isDataFetched is set to true, but not all are
    });
  }, []);

  const dismissModal = () => {
    dismiss();
    setCurrentPersonToEdit(placeholderPerson);
  };
  const savePerson = (updatedPerson: Person) => {
    sendEditedPersonToServer(updatedPerson);
    updatePeopleAfterEdit(updatedPerson);
  };
  const managers: Person[] = people.filter(
    ({ role }) => role === UserRole.MANAGER || role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN
  );
  const [present, dismiss] = useIonModal(EditPersonDialog, {
    dismiss: dismissModal,
    person: currentPersonToEdit,
    savePerson,
    managers,
  });
  useEffect(() => {
    if (currentPersonToEdit !== placeholderPerson) {
      present({
        onWillDismiss: dismissModal,
      });
    }
  }, [currentPersonToEdit]);

  const sendEditedPersonToServer = ({ userId, firstName, lastName, email, language, manager, role }: Person) => {
    const editedPerson: EditPersonInput = {
      userId,
      firstName,
      lastName,
      email,
      language,
      managerId: manager?.userId,
      role,
    };
    editPerson(editedPerson);
  };

  const updatePeopleAfterEdit = (updatedPerson: Person) => {
    const oldPerson: Person | undefined = people.find((p) => p.userId === updatedPerson.userId);
    if (!oldPerson) return;

    const isChangedToEmployeeRole = oldPerson.role !== UserRole.EMPLOYEE && updatedPerson.role === UserRole.EMPLOYEE;
    const peopleWithManagerRemoved: Person[] = isChangedToEmployeeRole
      ? removeManagerFromDirectReports(people, updatedPerson.userId)
      : people;
    const updatedPeople: Person[] = peopleWithManagerRemoved.map((person) => {
      if (person.userId === updatedPerson.userId) {
        return updatedPerson;
      }
      return person;
    });
    setPeople(updatedPeople);
  };

  const removeManagerFromDirectReports = (oldPeople: Person[], oldManagerId: string): Person[] => {
    return oldPeople.map((person) => {
      if (person.manager?.userId === oldManagerId) {
        return {
          ...person,
          manager: undefined,
        };
      }
      return person;
    });
  };

  const deleteCurrentPerson = () => {
    if (!currentPersonToDelete) return;

    deletePerson(currentPersonToDelete.userId);
    updatePeopleAfterDelete(currentPersonToDelete.userId);
    setCurrentPersonToDelete(undefined);
  };

  const updatePeopleAfterDelete = (deletedPersonId: string) => {
    const peopleWithoutDeletedPerson: Person[] = people.filter((p) => p.userId !== deletedPersonId);
    const peopleWithManagerRemoved: Person[] = removeManagerFromDirectReports(
      peopleWithoutDeletedPerson,
      deletedPersonId
    );
    setPeople(peopleWithManagerRemoved);
  };

  return (
    <>
      {!isDataFetched && <LoadingMessage />}
      <span className="data-grid-container">
        <table className="data-grid data-sheet">
          <thead>
            <tr>
              {headers.map((headerKey) => (
                <th key={headerKey} className="cell read-only">
                  {t(headerKey)}
                </th>
              ))}
              <th className="cell read-only">{t('label.user.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {people.map((person) => {
              const { firstName, lastName, email, language, role, manager, status } = person;
              const managerName: string = manager ? `${manager.firstName} ${manager.lastName}` : '----';
              const languageName: string = t(`language.name.${language}`);
              const roleName = t(`enum.userRole.${getEnumKeyByEnumValue(UserRole, role).toLowerCase()}`);
              const statusName = t(
                `enum.status.${getEnumKeyByEnumValue(Status, status ?? Status.ACTIVE).toLowerCase()}`
              );
              return (
                <tr key={email}>
                  <td className="cell">{firstName}</td>
                  <td className="cell">{lastName}</td>
                  <td className="cell">{email}</td>
                  <td className="cell">{languageName}</td>
                  <td className="cell">{roleName}</td>
                  <td className="cell">{managerName}</td>
                  <td className="cell">{statusName}</td>
                  <td className="cell">
                    {role !== UserRole.SUPER_ADMIN && (
                      <IonRow class="ion-justify-content-center">
                        <IonButton onClick={() => setCurrentPersonToEdit(person)}>{t('button.edit')}</IonButton>
                        <IonButton color="danger" onClick={() => setCurrentPersonToDelete(person)}>
                          {t('button.delete')}
                        </IonButton>
                      </IonRow>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </span>
      <DeleteConfirmation
        isOpen={currentPersonToDelete !== undefined}
        setIsOpen={() => setCurrentPersonToDelete(undefined)}
        deleteMe={deleteCurrentPerson}
        confirmButtonText="confirmation.delete.person.confirm"
        header="confirmation.delete.person.header"
        message="confirmation.delete.person.message"
      />
    </>
  );
};

export default PeopleListGrid;
