import { Language, Status, UserRole } from '../../constants/constants';
import { graphql } from '../../utils/api/apiUtils';

export interface Person {
  userId: string;
  cognitoId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  language: Language;
  manager?: Person;
  role: UserRole;
  status?: Status;
}

export interface PutPersonInput {
  firstName?: string;
  lastName?: string;
  email: string;
  language?: Language;
  managerFirstName?: string;
  managerLastName?: string;
  managerEmail?: string;
}

export const putPeople = async (people: PutPersonInput[]) => {
  const query = `
      mutation putPeopleMutation($people: [PutUserInput!]!) {
        putPeople(people: $people) {
          userId
          firstName
          lastName
          email
          language
          role
          status
          manager {
            userId
            firstName
            lastName
            email
          }
        }
      }
  `;
  const variables = { people };
  console.log('people put', await graphql(query, variables));
};

export const getActivePeople = async (): Promise<Person[]> => {
  const query = `
    query getAllPeopleQuery {
      getPeople {
        userId
        firstName
        lastName
        email
        language
        role
        status
        manager {
          userId
          firstName
          lastName
          email
        }
      }
    }
  `;
  const people = await graphql<Person[]>(query);
  console.log('got people', people);
  const activePeople = people.filter((p) => p.status === Status.ACTIVE);
  return activePeople;
};

export interface EditPersonInput {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  language?: Language;
  managerId?: string;
  role?: UserRole;
}

export const editPerson = async (person: EditPersonInput) => {
  const query = `
      mutation editPersonMutation($person: EditUserInput!) {
        editPerson(person: $person) {
          userId
          firstName
          lastName
          email
          language
          role
          status
          manager {
            userId
            firstName
            lastName
          }
        }
      }
  `;
  const variables = { person };
  console.log('person edited', await graphql(query, variables));
};

export const deletePerson = async (personId: string) => {
  const query = `
      mutation deletePersonMutation($personId: ID!) {
        deletePerson(personId: $personId)
      }
  `;
  const variables = { personId };
  await graphql(query, variables);
  console.log('person deleted', personId);
};
