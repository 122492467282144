import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { useParams } from "react-router";
import Toolbar from '../features/toolbar/Toolbar';

interface BillingPaymentProps {

}

const BillingPaymentPage: React.FC<BillingPaymentProps> = (props) => {
  // const { name } = useParams<{ name: string; }>()
  const title = 'Payment'

  return (
    <IonPage>
      <Toolbar title={title} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>Content here</div>
      </IonContent>
    </IonPage>
  )
}

export default BillingPaymentPage