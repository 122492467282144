import API, { GraphQLResult } from '@aws-amplify/api';
import { getIDToken } from '../auth/AuthUtils';

export const graphql = async <T>(query: string, variables?: object): Promise<T> => {
  try {
    const idToken: string = await getIDToken();
    const result = (await API.graphql({ query, variables }, { id: idToken })) as GraphQLResult;

    if (!result.data) {
      throw new Error('No data returned from query');
    }
    const data = result.data as any;
    const keys = Object.keys(data);
    return data[keys[0]]; // This only works if there is only one query/mutation included in the query
  } catch (response: any) {
    console.error('Errors in GraphQL query\nquery:', query, '\nvariables:', variables, '\nerrors:', response.errors);
    throw new Error('GraphQL query failed');
  }
};
