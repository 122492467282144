import { CognitoUser, CognitoUserSession, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { AuthCache } from './AuthCache';

//This assumes that "Amplify.configure(awsconfig)" has been run as part of the application startup

//Standard attributes gotten from https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
export enum CognitoPropertyKeys {
  //Standard Attributes that we actually use
  cognitoID = 'sub',
  email = 'email',
  language = 'locale',
  name = 'name',
  phone = 'phone_number',
  //Custom Attributes defined at pool creation time
  userID = 'custom:userID',
  externalID = 'custom:externalID',
  metadata = 'custom:metadata',
  /*
    Unsupported standard attributes (https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html)
    address
    birthdate
    family_name
    gender
    given_name
    middle_name
    nickname
    picture
    preferred_username
    profile
    updated_at
    website
    zoneinfo
     */
}

export function checkForcedLogout(error: any) {
  switch (
    ('' + error).trim() //Force a typecast that can be compared
  ) {
    case 'NotAuthorizedException: Access Token has been revoked':
      handleForcedLogout();
      break;
    case 'No current user':
      //Depending on context, such as the login screen, this could be ok
      //and is NOT a sign of a forced logout
      break;
    case 'UserNotFoundException: User does not exist.':
      //Depending on context, such as the login screen, this could be ok
      //and is NOT a sign of a forced logout
      break;
    case 'Network error':
      console.error('No network (no prefix)', error);
      break;
    case 'Error: Network error (had prefix)':
      console.error('No network', error);
      break;
    default:
      console.error('Authentication error is unknown:', error);
  }
}

export function handleForcedLogout() {
  console.error('Forced logout needs implemented');
  //TODO: Implement a UI notice for forced signout due to buddy login or explicit serverside logout
  try {
    Auth.signOut();
    AuthCache.invalidateCache();
  } catch (exception) {
    console.error('Attempting to force a logout there was an error', exception);
  }
}

export async function refreshSession() {
  try {
    await Auth.currentSession();
  } catch (exception: any) {
    checkForcedLogout(exception);
  }
}
/**
 * inspired by https://github.com/aws-amplify/amplify-js/issues/4396
 */
export async function forceRefreshCurrentSession() {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  await user.refreshSession(session.getRefreshToken(), async (err: any, data: any) => {
    if (err) {
      console.error('Error refreshing session', err);
      //reject(err);
    } else {
      //Success(ish). Nothing to do
    }
  });
}

export async function getIDToken(): Promise<string> {
  try {
    const session: CognitoUserSession = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (exception) {
    checkForcedLogout(exception);
    throw new Error('Failed to get ID token');
  }
}

export async function getCurrentUserAttributes() {
  try {
    const user: CognitoUser | undefined = await currentCognitoUser();

    if (user && user !== undefined) {
      //TODO: Only do this if they are offline, otherwise use await "Auth.userAttributes(user)"?
      //As long as  user parameters are simple and do not need a lot of syncing offline only access is probably sufficient
      return await getOfflineCurrentUserAttributes(user);
      //TODO: use this call if online
      //return await getOnlineCurrentUserAttributes(user);
    }
    return undefined;
  } catch (e) {
    console.error('Error running getCurrentUserAttributes', e);
    checkForcedLogout(e);
    return undefined;
  }
}

async function getOfflineCurrentUserAttributes(user: CognitoUser): Promise<ICognitoUserAttributeData[] | undefined> {
  var loadedAttributes: ICognitoUserAttributeData[] | undefined;

  const attributePromise = new Promise<ICognitoUserAttributeData[] | undefined>((resolve, reject) => {
    user.getUserData((err, attributes) => {
      if (err) {
        reject(err);
      } else {
        resolve(attributes?.UserAttributes);
      }
    });
  });
  await attributePromise
    .then((newAttributes) => {
      if (newAttributes !== undefined) {
        AuthCache.remoteRefresh(newAttributes);
        loadedAttributes = newAttributes;
      }
    })
    .catch((err) => {
      console.error('Error attempting to get current user offline attributes', err);
    });

  return loadedAttributes;
}

//TODO: Test this more.  Offline version has been tested a lot. but not this online version
async function getOnlineCurrentUserAttributes(user: CognitoUser): Promise<ICognitoUserAttributeData[] | undefined> {
  var loadedAttributes: ICognitoUserAttributeData[] | undefined;

  //In the trivial case this will be ok since we have few attributes that change, but once we get attributes that change we will need to make more reporte calls to refresh them
  const attributePromise = new Promise<ICognitoUserAttributeData[] | undefined>((resolve, reject) => {
    user.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  });
  await attributePromise
    .then((newAttributes) => {
      if (newAttributes !== undefined) {
        AuthCache.remoteRefresh(newAttributes);
        loadedAttributes = newAttributes;
      }
    })
    .catch((err) => {
      console.error('Error attempting to get current user online attributes', err);
    });

  return loadedAttributes;
}

export async function getCurrentUserAttribute(attributeName: CognitoPropertyKeys): Promise<string | undefined> {
  const attributes: ICognitoUserAttributeData[] | undefined = await getCurrentUserAttributes();

  let result: string | undefined = undefined;
  if (attributes) {
    const filterItems = attributes.filter((entry: ICognitoUserAttributeData) => {
      return entry.Name === attributeName;
    });
    if (filterItems && filterItems.length === 1) {
      result = filterItems[0].Value;
    }
  }

  return result;
}

export async function updateCurrentUserAttribute(
  attributeName: CognitoPropertyKeys,
  attributeValue: string
): Promise<void> {
  const user: CognitoUser | undefined = await currentCognitoUser();

  /**
      Certain AWS fields, especially phone_number, have strict formatting requirements.
      See https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
      We implement those here (where possible)
     */
  let value;
  if (attributeName === 'phone_number') {
    value = awsFormatPhone(attributeValue);
  } else {
    value = attributeValue;
  }

  if (user && user !== undefined) {
    //Intentionally no "await" since there is no reason (other than exceptions) to wait for the cognito update
    Auth.updateUserAttributes(user, {
      [attributeName]: value,
    });
  }
}

export async function currentCognitoUser(): Promise<CognitoUser | undefined> {
  await refreshSession();
  let session: CognitoUserSession | undefined = await currentCognitoSession();

  if (!session?.isValid()) {
    // handleForcedLogout();
    throw new Error('Session is invalid'); // TODO: Error thrown to handle incorrect verification code in SignIn#verifyOtp, will need to see how to handle buddy login separately
  }

  let result: CognitoUser | undefined = undefined;

  if (session && session.isValid()) {
    try {
      /*
            IMPORTANT: currentAuthenticatedUser != currentUserInfo
            Many of the functions that take user as a parameter require the
            CognitoUser returned by currentAuthenticatedUser
            and will fail with the object returned by currentUserInfo (no session function)
             */
      result = await Auth.currentAuthenticatedUser();
    } catch (exception) {
      checkForcedLogout(exception);
      result = undefined;
    }
  } else {
    result = undefined;
  }

  return result;
}

async function currentCognitoSession(): Promise<CognitoUserSession | undefined> {
  let result: CognitoUserSession | undefined = undefined;
  await Auth.currentSession()
    .then((session) => {
      result = session;
    })
    .catch((exception) => {
      console.error('Exception trying to get current session');
      checkForcedLogout(exception);
      result = undefined;
    });

  return result;
}

export async function syncUserID(): Promise<string> {
  const sub: string = (await getCurrentUserAttribute(CognitoPropertyKeys.cognitoID)) || '';
  await updateCurrentUserAttribute(CognitoPropertyKeys.userID, sub);
  return sub;
}

/**
 * This function fulfils the
 * "A phone number must start with a plus (+) sign, followed immediately by the country code. A phone number can only contain the + sign and digits. You must remove any other characters from a phone number, such as parentheses, spaces, or dashes (-) before submitting the value to the service."
 * requirement in AWS (https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html),
 * but assumes that the phone is US if no explicit country code is given.
 * AWS has some undocumented implicitly defined formatting requirements that appear to be based on country code
 * @param oldPhone
 */
export function awsFormatPhone(oldPhone: string): string {
  let result: string = oldPhone.trim().replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
  if (result.startsWith('+')) return result; //If there appears to be a country code provided, use it
  return '+' + result;
}

export const getIsLoggedIn = async (): Promise<boolean> => {
  try {
    const user: CognitoUser | any = await Auth.currentAuthenticatedUser();
    return user && user.getUsername();
  } catch {
    return false;
  }
};
