import React, { useEffect, useRef, useState } from 'react';

import * as wjcGrid from '@grapecity/wijmo.react.grid';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { PersonRow, placeholderPeopleData } from './peopleGridData';
import { useRecoilState } from 'recoil';
import { teamGridData, gridErrorState } from '../../../../data/useTeamGridData';
import { DataChangeAction } from '@grapecity/wijmo.grid.immutable';
import { ImmutabilityProvider } from '@grapecity/wijmo.react.grid.immutable';
import { gridValidator } from './PeopleValidations';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import '@grapecity/wijmo.styles/wijmo.css';
import './PeopleImportGrid.css';
import { IonBackButton, IonButton } from '@ionic/react';

const PeopleImportGrid: React.FC = () => {
  const gridRef = useRef<wjcGrid.FlexGrid>(null);
  const ipRef = useRef<ImmutabilityProvider>(null);

  const [gridState, setGridState] = useRecoilState(teamGridData);
  // Global state indicating true/false whether there are errors
  const [hasErrors, setHasErrors] = useRecoilState(gridErrorState);
  // Local state for tracking which cells have errors.
  const [errorCells, setErrorCells] = useState<string[]>([]);

  useEffect(() => {
    const placeholder: PersonRow[] = placeholderPeopleData;
    setGridState(placeholder);
    setHasErrors(false);
  }, []);

  // Set the global hasErrors based on whether cells with errors exist.
  useEffect(() => {
    if (errorCells.length > 0) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
    }
  }, [errorCells]);

  /**
   * @param s This will be the ImmutabilityProvider
   * @param e This will be the DataChangeEventArgs {action, itemIndex, newItem, oldItem}
   */
  const onDataChanged = (s: any, e: any) => {
    switch (e.action) {
      case DataChangeAction.Add:
        itemAdded(e);
        break;
      case DataChangeAction.Remove:
        itemRemoved(e);
        break;
      case DataChangeAction.Change:
        itemChanged(e);
        break;
      default:
        throw 'Unknown data action';
    }
  };

  const itemChanged = (e: any) => {
    setGridState((prevGridState) => {
      return [...prevGridState.slice(0, e.itemIndex), e.newItem, ...prevGridState.slice(e.itemIndex + 1)];
    });
  };

  const itemAdded = (e: any) => {
    setGridState((prevGridState) => {
      return [...prevGridState, e.newItem];
    });
  };

  const itemRemoved = (e: any) => {
    setGridState((prevGridState) => {
      return [...prevGridState.slice(0, e.itemIndex), ...prevGridState.slice(e.itemIndex + 1)];
    });
  };

  const flagError = (position: string) => {
    if (!errorCells.includes(position)) {
      setErrorCells((prev) => [...prev, position]);
    }
  };

  const clearError = (position: string) => {
    if (errorCells.includes(position)) {
      setErrorCells((prev) => prev.filter((cell) => cell !== position));
    }
  };

  // Note: Had to disable sorting {allowSorting} because it threw off the error validation display.
  // We can come back to that in the future.

  return (
    <div className="person-import-grid">
      <wjcGrid.FlexGrid ref={gridRef} showErrors={true} validateEdits={false} allowSorting={false} style={{ height: 'auto' }} autoGenerateColumns={false} allowAddNew allowDelete allowPinning="SingleColumn" 
      itemValidator={(row: number, col: number, parsing: boolean) => gridValidator(row, col, parsing, ipRef?.current, gridRef?.current, flagError, clearError)}>
        <ImmutabilityProvider itemsSource={gridState} dataChanged={onDataChanged} ref={ipRef} />
        <wjcGrid.FlexGridColumn header="First Name" binding="firstName" minWidth={200} isReadOnly={false}></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn header="Last Name" binding="lastName" minWidth={200} isReadOnly={false}></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn header="Email" binding="email" minWidth={200} maxWidth={500} width="*" isReadOnly={false}></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn header="Language" binding="language" isReadOnly={false}></wjcGrid.FlexGridColumn>
      </wjcGrid.FlexGrid>
    </div>
  );
};

export default PeopleImportGrid;
