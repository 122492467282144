import { atom } from 'recoil';

export interface PersonRow {
  firstName: string;
  lastName: string;
  email: string;
  language?: string;
  managerFirstName?: string;
  managerLastName?: string;
  managerEmail?: string;
}

// TODO: when I add 'dangerouslyAllowMutablility' clear doesn't work.
// If I don't, then we run into this issue when attempting to paste: https://github.com/facebookexperimental/Recoil/issues/299
// Seems like this is an either-or flag?
export const teamGridData = atom<PersonRow[]>({
  key: 'teamGridData',
  default: [],
  //dangerouslyAllowMutability: true
});

export const gridErrorState = atom<boolean>({
  key: 'gridErrorState',
  default: false,
});
